jQuery(document).ready(function ($) {

    $('.header').fadeIn(2000);

    if ($('#socialshareprivacy').length > 0) {
        $('#socialshareprivacy').socialSharePrivacy({
            "css_path": "/js/plugins/socialshareprivacy/socialshareprivacy.css",
            "lang_path": "/js/plugins/socialshareprivacy/lang/",
            "language": "de"
        });
    }
});

$('#maximage').maximage({
    cycleOptions: {
        fx: 'fade',
        speed: 3500, // Set the speed for CSS transitions in jQuery.maximage.css (lines 30 - 33)
        timeout: 5000,
        pause: 1
    },
    fillElement: '.slider',
    backgroundSize: 'cover',
    onFirstImageLoaded: function () {
        jQuery('#maximage').fadeIn('fast');
    }
});

$("#gmap").gmap3({
    map: {
        options: {
            center: [53.6463048, 10.05800720000002],
            zoom: 15,
            mapTypeId: "noPOI",
            mapTypeControl: true,
            navigationControl: true,
            scrollwheel: false,
            streetViewControl: false,
            draggable: false
        }
    },
    styledmaptype: {
        id: "noPOI",
        options: {
            name: "no POI"
        },
        styles: [
            {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [
                    {visibility: 'off'}
                ]
            }
        ]
    },
    marker: {
        values: [
            {latLng: [53.6463048, 10.05800720000002]}
        ],
        options: {
            draggable: false,
            clickable: false
        }
    }
});